import React, { useMemo, ReactElement } from 'react'

export type TrackingProviderProps = DBN.IReactDefaultProps

export interface ITrackingContext {
  PushMessage: (evt: string, message: Record<string, unknown>) => void
}

const TrackingContext = React.createContext<ITrackingContext>({
  PushMessage: () => {
    return null
  },
})

export default function TrackingProvider({
  children,
}: Readonly<TrackingProviderProps>): ReactElement {
  function PushMessage(evt: string, message: Record<string, unknown>): void {
    if (!window._mtm) return
    window._mtm.push({
      event: evt || 'custom.message',
      ...message,
    })
  }

  const contextValue = useMemo(() => ({ PushMessage }), [])

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  )
}

export { TrackingContext }
