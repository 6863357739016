import React, { ReactElement, useEffect, useState } from 'react'
import { globalHistory, useLocation } from '@reach/router'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'
import { defaultLang } from '@system/translations'

import { makeStyles } from '@material-ui/core/styles'
import { INavItemData } from '@components/navigation/menuNav'
import { Grid, Typography } from '@material-ui/core'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import useCustomCursor from '@system/hooks/useCustomCursor'

import useGlobalText from '@system/hooks/useGlobalText'
import useModuleTheme from '@system/hooks/useModuleTheme'
import Paragraph from '@components/text/paragraph'
import SocialIcon from '@components/core/socialicon'
import Link from '@components/core/link'
import Icon from '@components/core/icon'

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    left: '0px',
    paddingRight: theme.spacing(4),
    paddingLeft: 0,
    color: theme.palette.text.invert,
    background: 'transparent',
    transition: 'transform 0.6s ease, opacity 0.6s ease',
    zIndex: 50,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(16),
      paddingLeft: theme.spacing(12),
    },

    '&.dark': {
      color: theme.palette.text.invert,
    },
    '&.light': {
      color: theme.palette.text.primary,
    },
  },
  headerHide: {
    transform: 'translate(0, -20px)',
    opacity: 0,
    pointerEvents: 'none',
  },
  headerLogo: {
    position: 'relative',
    display: 'block',
    width: '56px',
    height: '56px',
    marginLeft: '2px',
    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        width: '48px',
        height: '48px',
        backgroundColor: '#29ace3',
        borderRadius: '50%',
        margin: '-24px',
        transform: 'scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        color: theme.palette.text.primary,
        '&::before': {
          transform: 'scale(1)',
        },
      },
    },
  },
  headerLogoImage: {
    position: 'relative',
    display: 'block',
    width: '55px',
    height: '56px',
  },
  headerNav: {
    display: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  headerNavList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  headerNavLink: {
    display: 'inline-block',
    paddingTop: theme.spacing(7),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    ...theme.typography.navigation,
    color: theme.palette.text.tertiary,
    '&:hover': {
      color: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      color: 'inherit',
    },
  },
  headerNavLinkExternal: {
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  headerLang: {
    display: 'none',
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      display: 'initial',
    },
  },
  headerLangList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '&:first-child': {
      '& $headerLangLink': {
        marginLeft: theme.spacing(-2),
      },
    },
    '&:last-child': {
      '& $headerLangLink': {
        marginRight: theme.spacing(-2),
      },
    },
  },
  headerLangLink: {
    display: 'inline-block',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    ...theme.typography.navigation,
    color: theme.palette.text.tertiary,
    textTransform: 'uppercase',
    '&:hover': {
      color: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      color: 'inherit',
    },
  },
  headerBurger: {
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  headerBurgerButton: {
    position: 'relative',
    appearance: 'none',
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    zIndex: 2,
    width: '24px',
    height: '24px',

    '& span': {
      position: 'absolute',
      display: 'block',
      width: '20px',
      height: '2px',
      backgroundColor: 'currentColor',
      top: '50%',
      left: '50%',
      margin: '-1px -10px',
      '&:first-child': {
        transform: 'translate(0, -4px)',
      },
      '&:last-child': {
        transform: 'translate(0, 4px)',
      },
    },
  },
  menu: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: theme.palette.text.invert,
    background: theme.palette.background.focus,
    paddingTop: theme.spacing(14),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1,
  },
  menuNav: {
    flex: '1 0 auto',
  },
  menuNavLink: {
    display: 'block',
    maxWidth: '250px',
    ...theme.typography.navigationmenu,
    color: theme.palette.text.tertiary,
    '&:hover': {
      color: 'inherit',
    },
    marginBottom: theme.spacing(10),
  },
  menuNavLinkExternal: {
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  menuCopy: {
    flex: '0 0 auto',
  },
  menuFooter: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuSocial: {},
  copyLink: {
    color: theme.palette.text.tertiary,
    '& > span::after': {
      bottom: '4px',
    },
    '&:hover': {
      color: 'inherit',
    },
  },
  socialIcon: {
    display: 'inline-block',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(8),
    },
  },
  langLink: {
    display: 'inline-block',
    ...theme.typography.navigationmenu,
    color: theme.palette.text.tertiary,
    textTransform: 'uppercase',
    '&:hover': {
      color: 'inherit',
    },
    marginLeft: theme.spacing(4),
  },

  opening: {
    '& $headerBurgerButton': {
      color: theme.palette.text.invert,
      transition: 'color 0.3s',
      '& span': {
        width: '26px',
        margin: '-1px -13px',
        transition: 'all 0.3s',
        '&:first-child': {
          transform: 'translate(0,0) rotate(45deg)',
        },
        '&:last-child': {
          transform: 'translate(0,0) rotate(-45deg)',
        },
      },
    },
    '& $menu': {
      transform: 'translate(0,0)',
      transition: 'transform 0.6s ease-out',
    },
    '& $menuNav': {
      transform: 'translate(0,0)',
      opacity: 1,
      transition: 'transform 0.6s ease-out 0.5s, opacity 0.6s ease-out 0.5s',
    },
    '& $menuCopy': {
      opacity: 1,
      transition: 'opacity 0.4s ease-out 0.8s',
    },
    '& $menuSocial': {
      opacity: 1,
      transition: 'opacity 0.4s ease-out 0.8s',
    },
  },
  opened: {
    '& $headerBurgerButton': {
      color: theme.palette.text.invert,
      '& span': {
        width: '26px',
        margin: '-1px -13px',
        '&:first-child': {
          transform: 'translate(0,0) rotate(45deg)',
        },
        '&:last-child': {
          transform: 'translate(0,0) rotate(-45deg)',
        },
      },
    },
    '& $menu': {
      transform: 'translate(0,0)',
    },
    '& $menuNav': {
      transform: 'translate(0,0)',
      opacity: 1,
    },
    '& $menuCopy': {
      opacity: 1,
    },
    '& $menuSocial': {
      opacity: 1,
    },
  },
  closing: {
    '& $headerBurgerButton': {
      color: 'inherit',
      transition: 'color 0.3s 0.3s',
      '& span': {
        width: '20px',
        margin: '-1px -10px',
        transition: 'all 0.3s 0.3s',
        '&:first-child': {
          transform: 'translate(0, -4px) rotate(0deg)',
        },
        '&:last-child': {
          transform: 'translate(0,4px) rotate(0deg)',
        },
      },
    },
    '& $menu': {
      transform: 'translate(-100%,0)',
      transition: 'transform 0.6s ease-in 0.3s',
    },
    '& $menuNav': {
      transform: 'translate(0,0)',
      opacity: 0,
      transition: 'opacity 0.4s ease-in',
    },
    '& $menuCopy': {
      opacity: 0,
      transition: 'opacity 0.4s ease-in',
    },
    '& $menuSocial': {
      opacity: 0,
      transition: 'opacity 0.4s ease-in',
    },
  },
  fastclosing: {
    '& $headerBurgerButton': {
      color: 'inherit',
      transition: 'color 0.3s 0.2s',
      '& span': {
        width: '20px',
        margin: '-1px -10px',
        transition: 'all 0.3s 0.2s',
        '&:first-child': {
          transform: 'translate(0, -4px) rotate(0deg)',
        },
        '&:last-child': {
          transform: 'translate(0,4px) rotate(0deg)',
        },
      },
    },
    '& $menuNav': {
      transform: 'translate(0,0)',
      opacity: 0,
      transition: 'opacity 0.4s ease-in',
    },
    '& $menuCopy': {
      opacity: 0,
      transition: 'opacity 0.4s ease-in',
    },
    '& $menuSocial': {
      opacity: 0,
      transition: 'opacity 0.4s ease-in',
    },
  },

  closed: {
    '& $headerBurgerButton': {
      color: 'inherit',
      '& span': {
        width: '20px',
        margin: '-1px -10px',
        '&:first-child': {
          transform: 'translate(0, -4px) rotate(0deg)',
        },
        '&:last-child': {
          transform: 'translate(0,4px) rotate(0deg)',
        },
      },
    },
    '& $menu': {
      transform: 'translate(100%,0)',
    },
    '& $menuNav': {
      transform: 'translate(0,40px)',
      opacity: 0,
    },
    '& $menuCopy': {
      opacity: 0,
    },
    '& $menuSocial': {
      opacity: 0,
    },
  },
}))

export type HeaderProps = {
  pageContext: DBN.PageHelpers.PageContext
  navItems: Array<INavItemData>
  hide?: boolean
}

export default function Header({
  pageContext,
  navItems,
  hide,
}: HeaderProps): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const scrollTrigger = useScrollTrigger({ threshold: 0 })
  const [menuState, setMenuState] = useState<
    'opening' | 'opened' | 'closing' | 'fastclosing' | 'closed'
  >('closed')
  const location = useLocation()
  const { currentTheme } = useModuleTheme()
  const { setCursorType } = useCustomCursor()
  const homeLink = pageContext.locale
    ? pageContext.locale === defaultLang
      ? `/`
      : `/${pageContext.locale}/`
    : '/'

  const openMenu = () => {
    document.body.style.overflow = 'hidden'
    setMenuState('opening')
    setTimeout(() => setMenuState('opened'), 1200)
  }

  const closeMenu = () => {
    document.body.style.overflow = ''
    setMenuState('closing')
    setTimeout(() => setMenuState('closed'), 1200)
  }

  const fastCloseMenu = () => {
    document.body.style.overflow = ''
    setMenuState('fastclosing')
    setTimeout(() => setMenuState('closed'), 500)
  }

  const toggleMenu = () => {
    switch (menuState) {
      case 'opened':
        closeMenu()
        break
      case 'closed':
        openMenu()
        break
    }
  }
  useEffect(() => {
    globalHistory.listen((action) => {
      if (location.pathname != action.location.pathname) {
        fastCloseMenu()
      } else {
        closeMenu()
      }
    })
  }, [fastCloseMenu, closeMenu, location])
  return (
    <header
      className={clsx(classes.headerRoot, currentTheme, {
        [classes.headerHide]: scrollTrigger || hide,
        [classes[menuState]]: menuState,
      })}
    >
      <Grid container alignItems="center">
        <Grid item xs={6} lg={1}>
          <GatsbyLink
            to={homeLink}
            className={clsx(classes.headerLogo)}
            onMouseEnter={() => setCursorType('hide')}
            onMouseLeave={() => setCursorType('')}
          >
            <Icon name="SfFamily" className={classes.headerLogoImage} />
          </GatsbyLink>
        </Grid>
        <Grid item component="nav" lg={10} className={clsx(classes.headerNav)}>
          <Grid
            container
            component="ul"
            justifyContent="center"
            alignItems="center"
            className={clsx(classes.headerNavList)}
          >
            {navItems?.map((item: INavItemData, i: number) => {
              return (
                <Grid item component="li" key={i}>
                  <Link
                    className={clsx(classes.headerNavLink, {
                      [classes.headerNavLinkExternal]: item.isExternal,
                    })}
                    to={item.href}
                    isExternal={item.isExternal}
                    isActive={
                      item.href && location.pathname.startsWith(item.href)
                    }
                  >
                    {item.title}
                  </Link>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid item xs={6} lg={1} className={clsx(classes.headerLang)}>
          {pageContext.page?.languages && (
            <Grid
              container
              component="ul"
              justifyContent="flex-end"
              alignItems="center"
              className={clsx(classes.headerLangList)}
            >
              {Object.keys(pageContext.page.languages).map((locale: string) => (
                <Grid item component="li" key={locale}>
                  <Link
                    className={classes.headerLangLink}
                    to={pageContext.page.languages[locale]}
                    isExternal={false}
                  >
                    {locale}
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} lg={1} className={clsx(classes.headerBurger)}>
          <button
            className={clsx(classes.headerBurgerButton)}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
          </button>
        </Grid>
      </Grid>
      <div className={classes.menu}>
        <div className={clsx(classes.menuNav)}>
          {navItems?.map((item, i) => {
            return (
              <Link
                key={i}
                className={clsx(classes.menuNavLink, {
                  [classes.menuNavLinkExternal]: item.isExternal,
                })}
                to={item.href}
                isExternal={item.isExternal}
                isActive={item.href && location.pathname.startsWith(item.href)}
              >
                {item.title}
              </Link>
            )
          })}
        </div>
        <div className={clsx(classes.menuCopy)}>
          <Typography variant="h3" component="p">
            {getText('navigation.copy')}
          </Typography>
          <Paragraph>
            {getText('navigation.email.link') &&
              getText('navigation.email.label') && (
                <Link
                  to={getText('navigation.email.link')}
                  className={clsx(classes.copyLink)}
                  isExternal={true}
                >
                  {getText('navigation.email.label')}
                </Link>
              )}
          </Paragraph>
        </div>
        <div className={clsx(classes.menuFooter)}>
          <div className={clsx(classes.menuSocial)}>
            <SocialIcon
              item={{
                name: 'LinkedIn',
                href: getText('social.linkedin.link'),
              }}
              className={clsx(classes.socialIcon)}
            />
            <SocialIcon
              item={{
                name: 'Twitter',
                href: getText('social.twitter.link'),
              }}
              className={clsx(classes.socialIcon)}
            />
            <SocialIcon
              item={{
                name: 'Instagram',
                href: getText('social.instagram.link'),
              }}
              className={clsx(classes.socialIcon)}
            />
          </div>
          {pageContext.page?.languages && (
            <div className={clsx(classes.menuLang)}>
              {Object.keys(pageContext.page.languages).map((locale: string) => (
                <Link
                  className={classes.langLink}
                  to={pageContext.page.languages[locale]}
                  isExternal={false}
                  key={locale}
                >
                  {locale}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
